import axios from "axios";
import moment from "moment";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { CDN_URL, REACT_APP_API_URL, headers } from "../../processENV";
import { postReq } from "../../../utils/apicall";
import { Unlock } from "react-feather";
import { BsHouse } from "react-icons/bs";

export class wellbeingprofile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      score_: 0,
      emotionalscore_: "",
      socialscore_: "",
      physicalscore_: "",
      personalscore_: "",
      professionalscore_: "",
      financialscore_: "",
      emotionalscale_: "",
      socialscale_: "",
      physicalscale_: "",
      personalscale_: "",
      professionalscale_: "",
      academicscale_: "",
      aspirationscale_: "",
      financialscale_: "",
      positive_areas: [],
      focus_areas: [],
      nolog_: true,
      _welluser: "",
      academicscore_: "",
      aspirationscore_: "",
      selfc_: false,
      selfcare: [],
      overall_: null,
      results_tn: false,
    };
  }
  isObjectEmpty(obj) {
    return Object.entries(obj).length === 0;
  }
  getProfile = async () => {
    await axios
      .post(
        REACT_APP_API_URL + "/wellbeing_tracker/",
        { patient_n_key: localStorage._patientKey },
        { headers }
      )
      .then((res) => {
        console.log("wellbeing_profile_response=>", res, res.data.data);
        if (
          res &&
          res.data.status &&
          res.data.status == "success" &&
          !this.isObjectEmpty(res.data.data)
        ) {
          this.setState({
            results_tn: res.data.lang,
            overall_: res.data.lang == "ta" ? res.data.data_tn : res.data.data,
            nolog_: false,
          });
        } else this.setState({ nolog_: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  async getData() {
    const resp = await postReq(`/business-wellbeing/get-results/`, {
      patient_n_key: localStorage?._patientKey,
    });
    console.log(resp);
    if (resp.data.status === "success") {
      this.setState({
        results_tn: resp.data.lang,
        overall_: resp.data.lang == "ta" ? resp.data.data_tn : resp.data.data,
        nolog_: false,
      });
    }
  }
  getUsr = async () => {
    axios
      .post(
        REACT_APP_API_URL + "/home_therapyforms/",
        { patient_n_key: localStorage._patientKey },
        { headers }
      )
      .then((response) => {
        console.log("++++therapy_forms+++++", response);
        if (response.data.status == "success") {
          this.setState({
            _welluser: response.data.org_type,
          });
          if (response.data.org_type === "College") {
            this.getProfile();
          } else if(response.data.org_type){
            this.getData();
          } 
          else {this.setState({nolog_:true})}
        }
        else {this.setState({nolog_:true})}
      });
  };
  capitalize = (str) => {
    let capital =
      str.charAt(0).toUpperCase() + str.slice(1).replaceAll("_", " ");
    return capital;
  };
  componentDidMount() {
    this.getUsr();
  }
  firstelement = () => {
    if (this.state.overall_) {
      const keys = Object.keys(this.state.overall_);
      const firstKey = keys[0];
      const createdOnValue = this.state.overall_[firstKey];
      return createdOnValue;
    }
  };
  isCreatedWithin14Days = () => {
    const today = moment().startOf("day");
    const createdOnDate = moment(this.firstelement()?.created_on);
    const dateDifferenceInDays = today.diff(createdOnDate, "days");
    const daysLeft = 14 - dateDifferenceInDays;
    const isWithin14Days =
      dateDifferenceInDays >= 0 && dateDifferenceInDays <= 14;
    const dateLeft = isWithin14Days
      ? today.clone().add(daysLeft, "days")
      : null;
      console.log(dateLeft,isWithin14Days,daysLeft)
    return {
      isWithin14Days: isWithin14Days,
      daysLeft: isWithin14Days ? daysLeft : null,
      dateLeft: moment(dateLeft).format("DD-MM-YYYY"),
    };
  };
  getTamilName = (str) => {
    if (str == "aspiration") {
      return "அபிலாஷை மதிப்பீடுகள்";
    } else if (str == "academic") {
      return "கல்விசார் மதிப்பீடு";
    } else if (str == "social") {
      return "சமூக வாழ்க்கை";
    } else if (str == "personal") {
      return "தனிப்பட்ட வாழ்க்கை";
    } else if (str == "physical") {
      return "உடல் ஆரோக்கியம்";
    } else if (str == "emotional") {
      return "மனவெழுச்சி ஆரோக்கியம்";
    }
  };
  render() {
    const { nolog_, _welluser, results_tn } = this.state;
    const data = this.state.overall_;
    return (
      <div className="col-md-12  ml-auto mt-md-5 mt-3">
        <div className="d-flex justify-content-between align-items-center col-md-10 mx-auto mt-md-2 mt-5 pt-5 pt-md-1">
          <button
            onClick={() => (window.location.href = "/home")}
            className="btn ml-md-3 ml-2 bg-white"
            style={{
              borderColor: "#215352",
              borderWidth: "1px",
              color: "#215352",
            }}
          >
            <BsHouse /> Go to dashboard
          </button>
          <button
            onClick={() => {
              window.location.href = "/userProfile";
            }}
            style={{ background: "#ddefe5", borderColor: "#215352" }}
            className="btn m-2 _text_color text_p_ font-weight-bold"
          >
            Personal Profile
          </button>
        </div>
        {nolog_ ? (
          <>
            <div>
              <div
                style={{ border: "1px solid #215352" }}
                className="col-md-6 mx-auto rounded shadow-sm mt-md-5 p-4"
              >
                <h4 className="header_font_">
                  Unlock your inner balance with our brief Well-Being
                  Assessment!
                </h4>
                <button
                  style={{ background: "#215352", color: "#fff" }}
                  className="btn mt-4 col-md-5 mx-auto btn-block font-weight-bold"
                  onClick={() => {
                    if (_welluser && _welluser == "College") {
                      window.location.href = "/wellbeing-profile";
                    } else {
                      window.location.href = "/careme-wellbeing-assessment";
                    }
                  }}
                >
                  Tap here to start
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {results_tn ? (
              <>
                <div className="col-md-6 mx-auto mt-md-n5 d-flex">
                  <img
                    src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                    className="img-fluid"
                    style={{ maxHeight: "100px" }}
                  />
                  <h5
                    style={{ opacity: 0.9 }}
                    className="header_font_ _text_color align-self-center"
                  >
                    உங்கள் தனிப்பயனாக்கப்பட்ட நல்வாழ்வுக்கான படிகள் 🗺️ - உங்கள்
                    பகுப்பாய்வு செய்யப்பட்ட மதிப்பீட்டு முடிவுகள்
                  </h5>
                </div>
                <div className="col-md-6 mx-auto mt-md-2 table-responsive">
                  <table className="table table-bordered pb-2  pb-md-4 table-fixed rounded table-responsive-md border">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                    </colgroup>
                    <thead>
                      <tr style={{ backgroundColor: "#ddefe5" }}>
                        <th className="py-4 _text_color text-center header_font_ h4"></th>
                        <th className="py-4 _text_color header_font_ h4">
                          கவனம் செலுத்த வேண்டியவை
                        </th>
                        <th className="py-4 _text_color text-center header_font_ h4">
                          மேம்படுத்த வேண்டியது
                        </th>
                        <th className="py-4 _text_color header_font_ h4">
                          சிறந்த நிலையில் உள்ளது
                        </th>
                        <th className="py-4 _text_color text-center header_font_ h4"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(this.state.overall_).map(
                        (category, index) => (
                          <tr
                            onClick={() =>
                              this.props.history.push(
                                "/wellbeing-tn-asses-view",
                                { data: this.state.overall_[category] }
                              )
                            }
                            className="py-5"
                            key={index}
                          >
                            <td className="text_p_ h5 _text_color py-4">
                              <p>{this.getTamilName(category)}</p>
                            </td>
                            <td>
                              {this.state.overall_[category].progress_status ===
                                "Low" && (
                                <div className="position-relative">
                                  <div className="banner-text">
                                    {
                                      this.state.overall_[category]
                                        .assessment_score
                                    }
                                  </div>
                                  <div
                                    className="bg-dark text-center mx-auto pt-4"
                                    style={{ height: "34px", width: "3px" }}
                                  ></div>
                                  <div className="progress mt-0">
                                    <div
                                      className="progress-bar "
                                      role="progressbar"
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#f5a97a",
                                      }}
                                      aria-valuenow="33.33"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              {this.state.overall_[category].progress_status ===
                                "Moderate" && (
                                <div className="position-relative">
                                  <div className="banner-text">
                                    {
                                      this.state.overall_[category]
                                        .assessment_score
                                    }
                                  </div>
                                  <div
                                    className="bg-dark text-center mx-auto pt-4"
                                    style={{ height: "34px", width: "3px" }}
                                  ></div>
                                  <div className="progress mt-0">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#74e86f",
                                      }}
                                      aria-valuenow="66.66"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="position-relative pr-0">
                              {this.state.overall_[category].progress_status ===
                                "Excellent" && (
                                <div
                                  style={{ width: "80%" }}
                                  className="mt-0 d-flex flex-row"
                                >
                                  <div className="position-relative flex-grow-1">
                                    <div className="banner-text">
                                      {
                                        this.state.overall_[category]
                                          .assessment_score
                                      }
                                    </div>
                                    <div
                                      className="bg-dark text-center mx-auto pt-4"
                                      style={{ height: "34px", width: "3px" }}
                                    ></div>
                                    <div className="progress ">
                                      <div
                                        className="progress-bar "
                                        role="progressbar"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#698ec9",
                                        }}
                                        aria-valuenow="100"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <button
                                style={{
                                  background: "#ddefe5",
                                  color: "#215352",
                                }}
                                className="btn mt-2"
                              >
                                பரிந்துரைகள்
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>

                  <div
                    class="mx-auto mt-md-5 mt-2 rounded"
                    style={{ backgroundColor: "rgba(0, 0, 0, 9%)" }}
                  >
                    <div class="rounded-box border p-3 d-flex rounded">
                      <b>
                        {" "}
                        📅 அடுத்த மதிப்பீட்டு தேதி:{" "}
                        {this.isCreatedWithin14Days().dateLeft}:{" "}
                      </b>{" "}
                      {this.isCreatedWithin14Days().daysLeft} நாட்களில்
                      அதிகாரப்பூர்வமான அறிமுகத்திற்கு காத்திருக்கவும்
                      முன்னேற்றம் மற்றும் மேம்பட்ட நல்வாழ்வுக்கான பயணத்தை தொடங்க
                      தயாராகுங்கள். இந்த அற்புதமான கருவியை உங்களுடன் பகிர்ந்து
                      கொள்வதற்கும் அது உங்கள் வாழ்க்கையில் ஏற்படுத்தும்
                      நேர்மறையான தாக்கத்தை பார்ப்பதற்கும் நாங்கள் ஆர்வமாக
                      உள்ளோம். முன் எப்போதும் இல்லாத வகையில் நீங்கள் உங்கள்
                      ஆரோக்கியத்திற்கு பொறுப்பேற்க வேண்டும்.{" "}
                    </div>
                  </div>
                  <h3 className="mt-3 mt-md-5 header_font_ _text_color ">
                    {" "}
                    போக்குகள் (நிலை)
                  </h3>
                  <div
                    class="mx-auto mt-md-3 mt-2 mb-md-5 rounded"
                    style={{ backgroundColor: "#dbd5ce" }}
                  >
                    <div class="rounded-box border rounded p-3">
                      <p class="text-center mx-auto py-1 h1 ">📈</p>
                      <div
                        style={{ opacity: 0.8, fontSize: "16px" }}
                        class="text_p_  font-weight-bold"
                      >
                        உங்கள் பதிவில் இரண்டு பதிவுகளுக்கு குறைவாக இருந்தால் பல
                        சந்தர்ப்பங்களில் உங்கள் நல்வாழ்வை பதிவு செய்ய நாங்கள்
                        உங்களை ஊக்குவிக்கிறோம் வெவ்வேறு காரணிகள் உங்கள் பயணத்தை
                        எவ்வாறு பாதிக்கலாம் மற்றும் மதிப்புமிக்க வடிவங்கள்
                        மற்றும் போக்குகளை கண்டறியலாம்
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className=" mx-auto col-xl-11 col-md-12 p-0 p-md-3">
                <div className="col-xl-8 col-md-10 mx-auto p-0 table-responsive">
                  <div className=" col-xl-10 mx-auto mt-2 mt-md-3 d-flex flex-column flex-md-row  p-0">
                    <img
                      src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
                      className="img-fluid"
                      style={{ maxHeight: "100px" }}
                    />
                    <h5
                      style={{ opacity: 0.9 }}
                      className="text_p_ font-weight-bold d-block d-md-none text-left ml-3 _text_color align-self-center"
                    >
                      Your Personalized Wellbeing Roadmap 🗺️ - Embracing Balance
                      and Growth 🌱
                    </h5>
                    <h2
                      style={{ opacity: 0.9 }}
                      className="text_p_ font-weight-bold d-md-block d-none  text-left ml-3 _text_color align-self-center"
                    >
                      Your Personalized Wellbeing Roadmap 🗺️ - Embracing Balance
                      and Growth 🌱
                    </h2>
                  </div>
                  {this.isCreatedWithin14Days()?.isWithin14Days?null:
                <div class="bg-light rounded-lg shadow-sm border p-2 p-md-5 mt-md-5 mt-3 mb-md-1 mb-3 text-center">
                <h4 class="font-weight-bold text-md-center text-left text-dark mb-md-3 mb-1">
                  Discover how you're doing today with a fresh Well-Being Assessment.
                </h4>
                <p class="text-muted mb-2 mb-md-4 text-md-center text-left">
                  Your well-being journey is ever-evolving, and it's important to stay in tune with your mind and body.  
                  Take the next step towards achieving balance and clarity in your life.  
                </p>
                <button
                  style={{backgroundColor:'#215352'}}
                  class="btn btn-lg text-white px-4 py-2 font-weight-bold shadow-sm"
                  onclick="if (_welluser && _welluser === 'College') {
                            window.location.href = '/wellbeing-profile';
                          } else {
                            window.location.href = '/careme-wellbeing-assessment';
                          }"
                >
                  Start Your Assessment
                </button>
              </div>
              }

                  <table className="table table-bordered pb-2 mt-md-4 pb-md-4 table-fixed rounded-lg table-responsive-md border">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "20%" }} />
                    </colgroup>
                    <thead className="rounded">
                      <tr
                        style={{ backgroundColor: "#ebf0ed" }}
                        className="rounded"
                      >
                        <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                          Category
                        </th>
                        <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                          Needs Attention
                        </th>
                        <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                          Improving
                        </th>
                        <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                          Well-balanced
                        </th>
                        <th className="py-4 _text_color  text-center text_p_ font-weight-bold h5"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        Object.keys(data).length > 0 &&
                        Object.keys(data).map((category, index) => (
                          <tr
                            onClick={() =>
                              this.props.history.push("/assesment-results", {
                                data: data[category],back:'/wellbeing-user-profile',
                              })
                            }
                            className="py-2"
                            key={index}
                          >
                            <td className="text_p_ h6 align-middle _text_color text-break">
                              <p className="p-0 m-0">
                                {this.capitalize(category)}
                              </p>
                            </td>
                            <td className="align-middle">
                              {data[category].progress_status === "Low" && (
                                <div className="position-relative">
                                  <div
                                    style={{ backgroundColor: "#f5a97a" }}
                                    className="banner-text rounded"
                                  >
                                    {data[category].assessment_score}
                                  </div>
                                  <div
                                    className="text-center mx-auto pt-4"
                                    style={{
                                      backgroundColor: "#f5a97a",
                                      height: "34px",
                                      width: "3px",
                                    }}
                                  ></div>
                                  <div
                                    style={{ height: "10px" }}
                                    className="progress mt-0"
                                  >
                                    <div
                                      className="progress-bar "
                                      role="progressbar"
                                      style={{
                                        width: "100%",
                                        height: "10px",
                                        backgroundColor: "#f5a97a",
                                      }}
                                      aria-valuenow="33.33"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="align-middle">
                              {data[category].progress_status ===
                                "Moderate" && (
                                <div className="position-relative">
                                  <div
                                    style={{ backgroundColor: "#73a671" }}
                                    className="banner-text rounded"
                                  >
                                    {data[category].assessment_score}
                                  </div>
                                  <div
                                    className="text-center mx-auto pt-4"
                                    style={{
                                      backgroundColor: "#73a671",
                                      height: "34px",
                                      width: "3px",
                                    }}
                                  ></div>
                                  <div
                                    style={{ height: "10px" }}
                                    className="progress"
                                  >
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: "100%",
                                        height: "10px",
                                        backgroundColor: "#73a671",
                                      }}
                                      aria-valuenow="66.66"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="align-middle">
                              {data[category].progress_status ===
                                "Excellent" && (
                                <div
                                  // style={{ width: "80%" }}
                                  className="mt-0 d-flex flex-row"
                                >
                                  <div className="position-relative flex-grow-1">
                                    <div
                                      style={{ backgroundColor: "#698ec9" }}
                                      className="banner-text rounded"
                                    >
                                      {data[category].assessment_score}
                                    </div>
                                    <div
                                      className="text-center mx-auto pt-4"
                                      style={{
                                        backgroundColor: "#698ec9",
                                        height: "34px",
                                        width: "3px",
                                      }}
                                    ></div>
                                    <div
                                      style={{ height: "10px" }}
                                      className="progress "
                                    >
                                      <div
                                        className="progress-bar "
                                        role="progressbar"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#698ec9",
                                          height: "10px",
                                        }}
                                        aria-valuenow="100"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="align-middle">
                              <button
                                style={{
                                  background: "#edf2f0",
                                  color: "#215352",
                                }}
                                className="btn d-flex  mx-auto align-items-center"
                              >
                                <Unlock size={18} className="mr-1" /> Unlock
                                Insights
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                   <div
                    className="md-mx-auto mx-2 mt-md-5 mt-2 rounded"
                    style={{ backgroundColor: "#f5f5f3" }}
                  >
                    <div className="rounded-box p-3 d-flex rounded">
                      <div
                        style={{ opacity: 0.7, fontSize: "16px" }}
                        className="text_p_  font-weight-bold"
                      >
                        <b style={{ textDecoration: "underline" }}>
                          📅 Next Assessment Date:{" "}
                          {this.isCreatedWithin14Days().dateLeft}
                        </b>{" "}
                        This periodic check-in helps us provide you with the
                        best support and guidance tailored to your evolving
                        needs. Remember, taking regular assessments allows for
                        better understanding and intervention. Your journey
                        towards better mental health is a continuous process,
                        and we're here to support you every step of the way.
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-3 pl-2 mt-md-5 header_font_ _text_color ">
                    Trends
                  </h3>
                  <div
                    className="md-mx-auto mx-2 mt-md-3  mt-2 mb-md-5 rounded"
                    style={{ backgroundColor: "#f7f4f2" }}
                  >
                    <div className="rounded-box rounded p-3">
                      <p className="text-center mx-auto py-1 h1 ">📈</p>
                      <div
                        style={{ opacity: 0.8, fontSize: "16px" }}
                        className="text_p_  font-weight-bold"
                      >
                        If your record has less than two entries, we encourage
                        you to record your well-being at least twice to unlock
                        the full potential of the chart. By recording your
                        well-being on multiple occasions, you can see how
                        different factors can affect your journey and spot
                        valuable patterns and trends.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(wellbeingprofile));
