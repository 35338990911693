import { useState, useEffect } from "react";
import axios from "axios";
import { REACT_APP_API_URL } from "../Components/processENV";

const headers = {
  "Content-Type": "application/json",
  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
};

export const useOverallData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${REACT_APP_API_URL}/business-wellbeing/get-results/`,
          { patient_n_key: localStorage._patientKey },
          { headers }
        );

        if (response.data.status === "success") {
          setData(response.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};

export const postReq = (url, data) => {
  return axios.post(REACT_APP_API_URL + url, data, { headers })
    .then((resp) => resp)
    .catch((err)=> err)
};
export const patchReq = (url, data) => {
  return axios.patch(REACT_APP_API_URL + url, data, { headers })
    .then((resp) => resp)
    .catch((err)=> err)
};
