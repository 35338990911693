import { ChevronLeft, ChevronRight } from "react-feather";
import RoundProgress from "./RoundProgress";

const QuestionPagination = ({ handlePrev, array, indx, handleNext }) => {
  return (
    <div className="btnview_ d-flex p-0 flex-row my-4 pr-4 justify-content-between col-md-7">
      <button
        onClick={handlePrev}
        className="prev_"
        style={{ opacity: indx > 0 ? 1 : 0.5 }}
      >
        <ChevronLeft />
      </button>
      <RoundProgress
        answeredCount={array.filter((i) => i.answer).length}
        questionCount={array.length}
        radius={25}
        strokeWidth={20}
        color="#f7f7f7"
      />
      <button
        disabled={!array[indx].answer}
        onClick={handleNext}
        className="prev_"
        style={{
          opacity: indx + 1 == array.length && !array[indx].answer ? 0.5 : 1,
        }}
      >
        <ChevronRight />
      </button>
    </div>
  );
};

export default QuestionPagination;
