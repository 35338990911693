import React from "react";
import { Button, Modal } from "react-bootstrap";

const ResultModal = ({show, onHide}) => {
  return (
    <Modal centered size="lg" show={show} onHide={onHide}>
      <Modal.Body>
        <h3 className="font-weight-light py-3 text-center header_font_">
          🎉 Congratulations on completing your assessment! 🎉
        </h3>
        <p className="font-weight-light h5 header_font_">
          At Careme Health, we believe in celebrating every step you take
          towards understanding and nurturing your mental well-being. Remember,
          whatever the outcome, it's the small, consistent changes that pave the
          way to success and resilience. Be proud of yourself for taking this
          step. We're here to support you on your journey. ❤️
        </p>
        <p className="font-weight-light py-2 h5 text-center header_font_">
          Stay strong and inspired. Your well-being is worth every effort.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Show Results</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResultModal;
