

 const RoundProgressBar = ({
    answeredCount,
    questionCount,
    radius,
    strokeWidth,
    color,
  }) => {
    const progress = answeredCount / questionCount;
    const circumference = 2 * Math.PI * radius;
    const progressStroke = progress * circumference;
    const remainingStroke = circumference - progressStroke;
    const progressColor = progress >= 0.5 ? "green" : "orange"; // Update the color based on the progress
  
    return (
      <div className="user-select-none">
        <svg width={radius * 2 + strokeWidth} height={radius * 2 + strokeWidth}>
          <circle
            cx={radius + strokeWidth / 2}
            cy={radius + strokeWidth / 2}
            r={radius}
            stroke={color}
            strokeWidth={strokeWidth / 4}
            fill="none"
          />
          <path
            stroke={progressColor}
            strokeWidth={strokeWidth / 4}
            strokeDasharray={[progressStroke, remainingStroke]}
            d={`M ${radius + strokeWidth / 2}, ${
              strokeWidth / 2
            } A ${radius}, ${radius} 0 ${progress > 0.5 ? 1 : 0}, 1 ${
              radius + strokeWidth / 2
            }, ${radius * 2 + strokeWidth / 2} A ${radius}, ${radius} 0 ${
              progress > 0.5 ? 1 : 0
            }, 1 ${radius + strokeWidth / 2}, ${strokeWidth / 2}`}
            fill="none"
          />
          <text
            x={radius + strokeWidth / 2}
            y={radius + strokeWidth / 2}
            fontSize={12}
            fill="#000"
            textAnchor="middle"
            alignmentBaseline="middle"
          >
            {answeredCount} / {questionCount}
          </text>
        </svg>
      </div>
    );
  };

  export default RoundProgressBar;