import React from "react";
import { Check } from "react-feather";

const WelcomeScreen = () => {
  return (
    <div className="inputcontainer_ col-md-12 mt-md-5 mt-3 ml-lg-2 px-1">
      <p className="toptext_ mt-md-2 pt-md-1 font-weight-bold h4 h2-md">
        Welcome to Careme Health's Ultimate Well-Being Check! 💡💪
      </p>
      <p className="secondtxt_ lead mt-3">
        Hey there! We're stoked to have you on this journey toward a healthier,
        happier YOU. 🌟✨ This isn't your typical questionnaire—it’s a vibe
        check for your mental, emotional, and professional well-being. We’ll
        dive into some key areas that impact your life:{" "}
      </p>
      <div className="checkbox_">
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          💼 Stress & Burnout
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🧠 Emotional Intelligence
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🎯 Workplace Satisfaction
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          🌈 Teamwork & Inclusion
        </button>
        <button className="checkboxtextbtn_ custom-fs shadow-sm rounded m-2 p-md-2 p-2 text_color_">
          📱 Digital Detox
        </button>
      </div>
      <p className="secondtxt_ lead mt-3">
        <span className="font-weight-bold">Why this matters: </span>By understanding your strengths and
        growth areas, we’ll equip you with personalized insights and actionable
        tips. It’s all about helping you level up in every aspect of your
        life—because you deserve nothing less than the best version of YOU! 💥🙌
      </p>
      <p className="btmtop_ mt-md-2 font-weight-bold lead">What’s next?</p>
      <ul className="mt-md-2 lead pl-3">
        <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
          ✅ Grab your coffee ☕ or settle in.
        </li>
        <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
          ✅ Be honest—it’s all anonymous and judgement-free.
        </li>
        <li className="d-flex secondtxt_ pb-1 pb-md-2 lead align-items-center">
          ✅ Let’s unlock your full potential together! 🚀
        </li>
      </ul>
      <p className="btmtop_ secondtxt_ lead mt-3">
        Your well-being is our priority, and we can't wait to see you shine! ✨
      </p>
    </div>
  );
};

export default WelcomeScreen;
