import moment from 'moment';
import React from 'react';
import { ArrowRight, Calendar, PlayCircle } from 'react-feather';
import { BsCalendar2CheckFill, BsCheck2Circle, BsCircle, BsNewspaper, BsPersonBadge } from 'react-icons/bs';
import { FaBook, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaMedium, FaPinterestSquare, FaTwitterSquare, FaVideo, FaYoutubeSquare } from 'react-icons/fa';
import { withRouter } from "react-router";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { RescheduleAction } from '../../../../Actions/RescheduleAction';
import { ScheduleAction } from '../../../../Actions/ScheduleAction';
import { ScheduleApp } from '../../../../Actions/ScheduleApp';
import { TherapyJourneyAction } from '../../../../Actions/TherapyJourneyAction';
import { postapi } from '../../../../Helpers/Api';
import Store from '../../../../Store';
import CAreMEHEALTH from '../../../../assets/images/CAreMEHEALTH.png';
import { CDN_URL, REACT_APP_API_URL, REACT_APP_BASE_URL, REACT_APP_BU_API_URL } from '../../../processENV';
import { ToastContainer, toast } from 'react-toastify';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const publicIp = require("react-public-ip");


class SelectedService extends React.Component{
  
        constructor(props) {
          super(props);
          this.state = {
              item:null,
              meetingId:'',
              profiledocname:'',
              serverTime:'',
              cancel:'',
              audioLock:false,
              confirmAppointment:[],
              pending:[],
              followUp:[],
              products:[],
              successfulPayment:[],
              therapyForms:[],
              credits_available:'',
              bu_logo:'',
              creditModal:false,
              nocreditModal:false,
              empDetails:[],
              selectP:'',
              pat_name:'',
              events_:[],
              eventsprev_:[],
              _wellbeing:false,
              _welluser:'',
              overall_remaining:'',
              _fol_therapist:false,
              _paid_therapist:false,
          };
      }

  componentDidMount(){
    this.getSelectedAppointment()
    this.getNewProd()
    this.getFollowUp()
    this.getPending()
    this.getPaid()
    this.getPatname()
    this.getCredit()
    this.getEvent()
    this.getTherapyAsses()
    this.interval = setInterval(() =>  this.getOrgTime(), 2000);
  }
  componentWillUnmount(){
    clearInterval(this.interval)
  }
  getEvent=async()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
    fetch(`${REACT_APP_BU_API_URL}/carme_events/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),

    }).then(response => response.json())
    .then((response)=>{
       if(response.status ==="success"){
         console.log('events_',response)
         this.setState({events_: response.alldata})
       }
     })
     fetch(`${REACT_APP_API_URL}/list_webinar/`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      }
    }).then(response => response.json())
    .then((response)=>{
       if(response){
         console.log('eventsprev_',response)
         this.setState({eventsprev_: response.results})
       }
     })
     
  }
  getTherapyAsses=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
    fetch(`${REACT_APP_API_URL}/home_therapyforms/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),

    }).then(response => response.json())
    .then((response)=>{
       if(response.status ==="success"){
         console.log(response)
         this.setState({
            therapyForms: response.data,   
            _wellbeing:response.welbeing,
            _welluser:response.org_type})
       }
     })
  }
  getLogo=async()=>{
    
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
  }
    fetch(`${REACT_APP_BU_API_URL}/bu_org_details/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
     body: JSON.stringify(data),
    }).then(response => response.json())
    .then((response)=>{
      console.log('bu_details',response)
       if(response){
         this.setState({overall_remaining: response.overall_remaining,bu_logo:response.logo})
       }
     })
  }
  getCredit = async() =>{
    this.getLogo()
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
  }
    fetch(`${REACT_APP_BU_API_URL}/bu_credit_check/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
     body: JSON.stringify(data),
    }).then(response => response.json())
    .then((response)=>{
      console.log('credit_Available',response)
       if(response){
         this.setState({credits_available: response.org_credit})
       }
     })
   }
  getOrgTime = async() =>{
    fetch(`${REACT_APP_API_URL}/org_current_time/`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
    }).then(response => response.json())
    .then((response)=>{
       if(response.status ==="success"){
         this.setState({serverTime: response.data})
       }
     })
   }
  dateConversion = (rdate) =>{
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return  (date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + " at " +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) + " " + (parseInt(date.getHours()) < 12 ? "AM" : " PM") )
  }

  dateConversionTherapy = (rdate) =>{
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return  (date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear() )
  }
  
  cancelAppointment = (aKey, reason) =>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
      "appointment_n_key": aKey,
      "status":"Cancelled",
     "cancelled_reason":reason
  }
  console.log(data)
  fetch(`${REACT_APP_API_URL}/app_cancel_appoint/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('cancel appointment',res.data)
        if(res.status === "success"){
          this.getSelectedAppointment()
        }else{
          console.log('cancel appointment',res.data)
        }
    })
  }
  SchedulePendng=async(key)=>{
      let item=key
      console.log(item)
      Store.dispatch(ScheduleApp({
        data1:item
      }))
      let jsonData = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key:item.app_prod_grp_key,
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(jsonData),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("cancel appointment", res.data);
          if (res.status == "success") {
                if(res.data[0].is_active==1){
                  this.setState({_fol_therapist:false})
                  let appointmentDetail=JSON.stringify({
                    doc_app_id:res.data[0].employee_n_key,
                    app_prod_grp_key:item.app_prod_grp_key,
                    app_payment_n_key:item.app_payment_n_key,
                    addon:item.addon||false,
                })
                localStorage.setItem('therapy_details',appointmentDetail)
                this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                }
                else{
                  this.setState({
                    selectP: item,
                    creditModal: true,
                    empDetails: res.data,
                    _fol_therapist:true
                  });
                }
          }
          else{
            this.props.history.push(`/scheduleapp/followup/id`,{data1:item})    
            }
        });
  }

  scheduleApp=async(key)=>{
    console.log(key)
    let item=key
    const ptkey=localStorage._patientKey
    if(this.state.credits_available >0){
    if(item){
      let jsonData = {
        patient_n_key: ptkey,
        app_prod_grp_key: item.app_prod_grp_key,
        app_payment_n_key:item.app_payment_n_key,
        doc_app_id:item.doc_app_id,
        journey_id:item.journey_id
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(jsonData),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("cancel appointment", res.data);
          if (res.status == "success") {
            if(res.data[0].is_active==1){
              this.setState({_fol_therapist:false})
              fetch(`${REACT_APP_BU_API_URL}/bu_followup_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then((response)=>{
                  let appointmentDetail=JSON.stringify({
                    doc_app_id:res.data[0].employee_n_key,
                    app_prod_grp_key:item.app_prod_grp_key,
                    app_payment_n_key:response.app_payment_n_key,
                    addon:item.addon||false, 
                })
                localStorage.setItem('therapy_details',appointmentDetail)
                this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                })
              }
            else{
              this.props?.history?.push('/creditsleft',{data:item,empDetails:res.data})
            }
          }
        });
    } 
    }
  else{
    let jsonData = {
      patient_n_key: ptkey,
      app_prod_grp_key: item.app_prod_grp_key,
      app_payment_n_key:item.app_payment_n_key,
      doc_app_id:item.doc_app_id,
      journey_id:item.journey_id
    };
    const res = await postapi('/previous_check/',jsonData)
    if (res.status == "success") {
      if(res.data[0].is_active==1){
        Store.dispatch(TherapyJourneyAction({
          item:item,
          action:'Followup'
        }))
        this.props.history.push(`/therapyjourney/selectSession/`)
      }
      else {
        this.props?.history?.push('/therapistleft',{data:item,empDetails:res.data})
      }
    }
  } 
  }
        
  rescheduleAppointment = (item) =>{
    if(item.overall_status === "Rescheduled"){
      toast.error(`Appointment already rescheduled once, please contact support team!`, {
        position: "top-center",
        autoClose: 5000,
      })
    }
    else{
      Store.dispatch(RescheduleAction({
        prodId:item
    }));
    this.props.history.push(`/reschedule/appointment/date`,{data:item})
    }
  }
  scheduleAppointment =(item) =>{
    if(item){
      let jsonData = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
        app_payment_n_key:item.app_payment_n_key,
      };
      fetch(`${REACT_APP_API_URL}/previous_check/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(jsonData),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("cancel appointment", res.data);
          if (res.status == "success") {
            if(res.data[0].is_active==1){
              this.setState({_paid_therapist:false})
                  let appointmentDetail=JSON.stringify({
                    doc_app_id:res.data[0].employee_n_key,
                    app_prod_grp_key:item.app_prod_grp_key,
                    app_payment_n_key:item.app_payment_n_key,
                    addon:item.addon||false,
                })
                localStorage.setItem('therapy_details',appointmentDetail)
                this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
              }
            else{
              this.props.history.push("/creditsleft",{
                selectP: item,
                creditModal: true,
                empDetails: res.data,
                _paid_therapist:true
              });
            }
          }
          else{           
            if(item.title==='Psychiatric Consultation'){
              let selectedProblem={app_prod_n_key:'APP_PROD-3'};
              Store.dispatch(ScheduleAction({
                scheduleStep:0,
                scheduleCurrentStep:0,
                problem:selectedProblem,
                mode:'Video',
                data1:item
            }));
            }
            else{
              Store.dispatch(ScheduleAction({
                data1:item,
                scheduleStep:0,
                scheduleCurrentStep:0,
              }))
            }
            this.props.history.push('/schedule/appointment',{data1:item})
          }
        });
    }    
  
  }
  sessionPay=(item)=>{
    Store.dispatch(TherapyJourneyAction({
      item:item,
      action:'Followup'
    }))
    this.props.history.push(`/therapyjourney/selectSession/`)
  }
  getNewProd=async()=>{
    let data={
        "ip": await publicIp.v4() || "",
        "patient_n_key": localStorage.getItem('_patientKey'),
    }
    fetch(`${REACT_APP_API_URL}/products_groups/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
        console.log('new prod',res.data)
          if(res.status === "success"){
            this.setState({products:res.data})
          }else{
            console.log('new prod',res.data)
          }
      })
  }

  async joinFreeChat(){
    const pt_key = localStorage._patientKey;
    const JsonData={
        patient_n_key :localStorage._patientKey
    }
    fetch(REACT_APP_API_URL+'/counselling_room/', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(JsonData),
      }).then(response => response.json())
      .then(async (res)=>{
        if(res.status==='success'){
          let JsonData1 ={
            channelName: res.channelid,
            uid: pt_key,
          };
          fetch(REACT_APP_API_URL+'/agora_rtctoken/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
            },
            body: JSON.stringify(JsonData1),
            }).then(response => response.json())
            .then(async (resp)=>{
              if(resp.status=='success'){
                localStorage.uid=pt_key
                localStorage.channelName=res.channelid
                this.props.history.push('/chatscreen',{
                  channelId: res.channelid,
                token: resp.rtm_data,
                uid: pt_key,
                typeofchat: 'CounsellingRoom',
                freeChat:res,
                firstChat:res.new_user,
                doctor_photo:this.state.chat_item&& this.state.chat_item?.support_chat && this.state.chat_item?.support_chat.length !==0?this.state.chat_item?.support_chat[0]?.doctor_photo:null,
                doctor_name:this.state.chat_item&& this.state.chat_item?.support_chat && this.state.chat_item?.support_chat.length !==0?this.state.chat_item.support_chat[0]?.doctor_name:null,
                disable:this.state.chat_item&& this.state.chat_item?.support_chat && this.state.chat_item?.support_chat.length !==0?this.state.chat_item.support_chat[0]?.expired:false,
                })
              }
            })
        }
      })
  }

  getFollowUp=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey'),
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_recommended/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('followup service',res)
        if(res.status === "success"){
          this.setState({followUp:res.data})
        }else{
          console.log('followup service',res.data)
        }
    })
  }
  getPaid=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_payment/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('paid service',res.data)
        if(res.status === "success"){
          this.setState({successfulPayment:res.data})
        }else{
          console.log('pending service',res.data)
        }
    })
  }
  getPending=()=>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_followups/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('pendinsg service',res.data)
        if(res.status === "success"){
          this.setState({pending:res.data})
        }else{
          console.log('pendissng service',res.data)
        }
    })
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  
    getPatname=()=>{
      let data = {
        "patient_n_key": localStorage.getItem('_patientKey')
    }
    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
  
    }).then(response => response.json())
    .then((res)=>{
      this.setState({pat_name:res.data.first_name})
    })
    }

  getSelectedAppointment = () =>{
    let data = {
      "patient_n_key": localStorage.getItem('_patientKey')
  }
  
  fetch(`${REACT_APP_API_URL}/upcoming_appoints/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
    },
    body: JSON.stringify(data),
    }).then(response => response.json())
    .then(async (res)=>{
      console.log('selected service',res.data)
        if(res.status === "success"){
          this.setState({confirmAppointment:res.data})
          // if(res.data.sucessfulappointment){
          //   this.props.setAppointmentStatus(res.data?.sucessfulappointment ? res.data?.sucessfulappointment : null)
          //   this.props.setTherapyStatus(res.data?.therapy_journey ? res.data?.therapy_journey : null)
          // localStorage.setItem('sucessfulappointment',JSON.stringify(res.data.sucessfulappointment))
          // }
        }else{
          console.log('selected service',res.data)
        }
    })
  }
  changeAudio(){
    const timer = setTimeout(() => {
      this.setState({audioLock:false})
    }, 240000);
    return () => clearTimeout(timer);
  }
  getMeetingid = (item,id) =>{
    if(item.mode_of_telehealth ==='Video'){
      let data = {
        "patient_n_key": localStorage.getItem('_patientKey'),
          "appointment_n_key":id
    }
    
    fetch(`${REACT_APP_API_URL}/join_now_app/`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
      },
      body: JSON.stringify(data),
      }).then(response => response.json())
      .then(async (res)=>{
        console.log('meeting id',res.data)
          if(res.status === "success"){
            this.setState({meetingId:res.data})
            let id = res.data.split('/')[4]
            console.log('meeting Id',id)
            window.location.replace(`${REACT_APP_BASE_URL}/patient/`+ id)
          }else{
            console.log('meeting id',res.data)
          }
      })
    }
   if(item.mode_of_telehealth ==='Audio'){
     this.setState({audioLock:true},()=>{this.changeAudio()})
    var now_time = "";
fetch(`${REACT_APP_API_URL}/org_current_time/`, {
method: "GET",
headers: {
  "Content-Type": "application/json",
  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
},
})
.then((response) => response.json())
.then((response) => {
  if (response.status === "success") {
    now_time = response.data;
    var meetingTime = "";
    meetingTime = item.appointment_date;
    const currentTime = moment(response.data).toDate();
    console.log("This_is_current_time", currentTime);
    console.log(moment(this.state.serverTime).toDate());
    var meetingDate = moment(meetingTime).toDate();
    let differenceTIme =
      (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
    var differenceSeconds = parseInt(differenceTIme) / 60;
    if(differenceSeconds <= 10){
      let data = {
        org_n_key: "ORGID-1",
        appointment_n_key: id,
      };
      fetch(`https://getehr.curegrid.co/api/connect_audio/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status === "success") {
            console.log("audio id", res);
          } else {
            console.log("meeting id", res.data);
          }
        });
    }
    else {alert(`Your Appointmet will beign at ${moment(item.appointment_date).format('DD-MM-YYYY')} at ${item.appointment_time}`)}
  }
})
.catch((error) => {
  console.error("Error fetching current time:", error);
});

   }
   if(item.mode_of_telehealth ==='Chat'){
    var now_time=''
    fetch(`${REACT_APP_API_URL}/org_current_time/`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
          },
        }).then(response => response.json())
        .then((response)=>{
           if(response.status ==="success"){
             now_time= response.data
             var meetingTime = '';
            meetingTime = item.appointment_date;
            const currentTime = moment(response.data).toDate();
            console.log('This_is_current_time', currentTime);
            console.log(moment(this.state.serverTime).toDate());
            var meetingDate = moment(meetingTime).toDate();
            let differenceTIme = (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
            var differenceSeconds = parseInt(differenceTIme) / 60;
    
            if(differenceSeconds <= 10){
              let JsonData1 ={
                channelName:item.appointment_n_key,
                uid: localStorage._patientKey,
              };
              fetch(REACT_APP_API_URL+'/agora_rtctoken/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
                },
                body: JSON.stringify(JsonData1),
                }).then(response => response.json())
                .then(async (resp)=>{
                  if(resp.status=='success'){
              localStorage.uid=localStorage._patientKey
              localStorage.channelName=item.appointment_n_key
              this.props.history.push('/chatscreen',{
                  channelId: item.appointment_n_key,
                  empKey: item?.doc_app_id,
                  token: resp.rtm_data,
                  uid: item.patient_n_key,
                  mode: item.mode_of_telehealth,
                  typeofchat: 'TeleHealth',
                  planitem: item,
                  doctor_photo:item.doctor_profile,
                  doctor_name:item.doctor_name,
              })
            }})
          }
            else{
              this.props.history.push('/waitingscreen',{
                item:item,
                typeofchat: 'TeleHealth',
              })
            }
    
    
           }
         })
    
  }
  }
  cancelReason=async(item)=>{
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Could you tell us the reason for cancellation',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here',
      },
      customClass: {
        validationMessage: 'my-validation-message'
      },
      showCancelButton: true,
      preConfirm: (value) => {
        if (value.length <= 15) {
          Swal.showValidationMessage(
            ' Reason should be atleast 15 characters!'
          )
        }
        else{
          this.cancelAppointment(item&&item.appointment_n_key, value)
        }
      }
    
    })
  }

showbutton=(item)=>{
let nowDate = moment(this.state.serverTime).toDate()
let appDate = moment(item.appointment_date).toDate()
var diff = (appDate.getTime() - nowDate.getTime())/1000
var differenceSeconds = parseInt(diff) / 60 ;
if(differenceSeconds >= 60) {
  return true
}
else return false
}
 showAppointment=(item)=>{
  let nowDate = moment(this.state.serverTime).toDate()
  let appDate = moment(item.appointment_date).toDate()
  var diff = (appDate.getTime() - nowDate.getTime())/1000
  var differenceSeconds = parseInt(diff) / 60 ;
  if(differenceSeconds >= -180) {
  return true
  }
  else return false
 } 


 goToPayment = async (i, title) => {
  const { confirmAppointment, successfulPayment, followUp, pending } =
    this.state;
  const ptkey = localStorage.getItem("_patientKey");
  const initial = "therapy";
  console.log(i, title);
  if (title == "APP-PROD-CAT-1") {
    if (confirmAppointment.length !== 0&&confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
    .length !== 0) {
      confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
        .length !== 0
        ? this.getMeetingid(
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
            )[0],
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
            )[0].appointment_n_key
          )
        : console.log("no_confirmdata");
    }
   else if (successfulPayment.length !== 0&&successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
    .length !== 0) {
      successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
        .length !== 0
        ? this.scheduleAppointment(
            successfulPayment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
            )[0]
          )
        : console.log("no_paydata");
    }
   else if (followUp.length !== 0&&followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
    .length !== 0) {
      followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
        .length !== 0
        ? this.scheduleApp(
            followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0],
            0
          )
        : console.log("no_therapydata");
    }
   else if (pending.length !== 0&&pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
    0) {
      pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
      0
        ? this.SchedulePendng(
            pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
              )
        : console.log("no_sessiondata");
    }
   else if (
      (pending.length !== 0 &&
        pending?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
        )) ||
      (confirmAppointment.length !== 0 &&
        confirmAppointment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
        )) ||
      (successfulPayment.length !== 0 &&
        successfulPayment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
        )) ||
      (followUp.length !== 0 &&
        followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-1")))
    ) {
      console.log("yes");
    } else {
      console.log('wer')
      if (this.state.credits_available >= 1) {
        let jsonData = {
          patient_n_key: ptkey,
          app_prod_grp_key: i.app_prod_grp_key,
        };
        fetch(`${REACT_APP_API_URL}/previous_check/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("cancel appointment", res.data,jsonData);
            if (res.status == "success") {
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then(async (resp) => {
              if(res.data[0].is_active==1){
                          let appointmentDetail=JSON.stringify({
                          doc_app_id:res.data[0].employee_n_key,
                          app_prod_grp_key:i.app_prod_grp_key,
                          app_payment_n_key:resp.app_payment_n_key,
                          })
                          localStorage.setItem('therapy_details',appointmentDetail)
                          this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
                 }
              else{
                let py_=i
                py_.app_payment_n_key=resp.app_payment_n_key
                this.props.history.push("/creditsleft",{
                  selectP: py_,
                  creditModal: true,
                  empDetails: res.data,
                });
              }
            })
        }
        else{
          let jsonData = {
            patient_n_key: ptkey,
            app_prod_grp_key: i.app_prod_grp_key,
          };
          fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(jsonData),
          })
            .then((response) => response.json())
            .then((response)=>{
              Store.dispatch(ScheduleAction({
                data1:i,
                app_payment_n_key:response.app_payment_n_key,
                scheduleCurrentStep:0,
                scheduleStep:0,
              }))
            this.props.history.push('/schedule/appointment',{data1:i,app_payment_n_key:response.app_payment_n_key})
            })
        }
      });
  } else {
        this.setState({
          selectP: i,
          nocreditModal: true,
        });
      }
    }
  }
  if (title == "APP-PROD-CAT-2") {
    if (confirmAppointment.length !== 0&& confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
    .length !== 0) {
      confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
        .length !== 0
        ? this.getMeetingid(
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
            )[0],
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
            )[0].appointment_n_key
          )
        : console.log("no_confirmdata");
    }
   else if (successfulPayment.length !== 0&&successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
    .length !== 0) {
      successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
        .length !== 0
        ? this.scheduleAppointment(
            successfulPayment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
            )[0]
          )
        : console.log("no_paydata");
    }
    else if (followUp.length !== 0&& followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
    .length !== 0) {
      followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
        .length !== 0
        ? this.scheduleApp(
            followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0],
            0
          )
        : console.log("no_therapydata");
    }
    else if (pending.length !== 0&&  pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
    0) {
      pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
      0
        ? this.SchedulePendng(
            pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
            )
        : console.log("no_sessiondata");
    }
    else if (
      (pending.length !== 0 &&
        pending?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
        )) ||
      (confirmAppointment.length !== 0 &&
        confirmAppointment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
        )) ||
      (successfulPayment.length !== 0 &&
        successfulPayment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
        )) ||
      (followUp.length !== 0 &&
        followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-2")))
    ) {
      console.log("yes");
    } else {
      if (this.state.credits_available >= 1) {
        let jsonData = {
          patient_n_key: ptkey,
          app_prod_grp_key: i.app_prod_grp_key,
        };
        fetch(`${REACT_APP_API_URL}/previous_check/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("cancel appointment", res.data);
            if (res.status == "success") {
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then(async (resp) => {
              if(res.data[0].is_active==1){
                          let appointmentDetail=JSON.stringify({
                          doc_app_id:res.data[0].employee_n_key,
                          app_prod_grp_key:i.app_prod_grp_key,
                          app_payment_n_key:resp.app_payment_n_key,  
                          })
                          localStorage.setItem('therapy_details',appointmentDetail)
                          this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
                 }
              else{
                let py_=i
                py_.app_payment_n_key=resp.app_payment_n_key
                this.props.history.push("/creditsleft",{
                  selectP: py_,
                  creditModal: true,
                  empDetails: res.data,
                });
              }
            })
        }
        else{
          let jsonData = {
            patient_n_key: ptkey,
            app_prod_grp_key: i.app_prod_grp_key,
          };
          fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(jsonData),
          })
            .then((response) => response.json())
            .then((response)=>{
              Store.dispatch(ScheduleAction({
                data1:i,
                scheduleStep:0,
                scheduleCurrentStep:0,
                app_payment_n_key:response.app_payment_n_key
              }))
            this.props.history.push('/schedule/appointment',{data1:i,app_payment_n_key:response.app_payment_n_key})
            })
        }
      });
  }  else {
        this.setState({
          selectP: i,
          nocreditModal: true,
        });
      }
    }
  }
  if (title == "APP-PROD-CAT-3") {
    if (confirmAppointment && confirmAppointment.length !== 0&&confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
    .length !== 0) {
      confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
        .length !== 0
        ? this.getMeetingid(
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
            )[0],
            confirmAppointment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
            )[0].appointment_n_key
          )
        : console.log("no_confirmdata");
    }
    else if (successfulPayment && successfulPayment.length !== 0&&successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
    .length !== 0) {
      successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
        .length !== 0
        ? this.scheduleAppointment(
            successfulPayment.filter(
              (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
            )[0]
          )
        : console.log("no_paydata");
    }
    else if (followUp && followUp.length !== 0&& followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
    .length !== 0) {
      followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
        .length !== 0
        ? this.scheduleApp(
            followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0],
            0
          )
        : console.log("no_therapydata");
    }
    else if (pending && pending.length !== 0&&  pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
    0) {
      pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
      0
        ? this.SchedulePendng(
            pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
            )
        : console.log("no_sessiondata");
    }
    else if (
      (pending.length !== 0 &&
        pending?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
        )) ||
      (confirmAppointment.length !== 0 &&
        confirmAppointment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
        )) ||
      (successfulPayment.length !== 0 &&
        successfulPayment?.some((i) =>
          i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
        )) ||
      (followUp.length !== 0 &&
        followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-3")))
    ) {
      console.log("yes");
    } else {
      if (this.state.credits_available >= 1) {
        let jsonData = {
          patient_n_key: ptkey,
          app_prod_grp_key: i.app_prod_grp_key,
        };
        fetch(`${REACT_APP_API_URL}/previous_check/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(jsonData),
        })
          .then((response) => response.json())
          .then(async (res) => {
            console.log("cancel appointment", res.data);
            if (res.status == "success") {
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then(async (resp) => {
                if(res.data[0].is_active==1){
                      let appointmentDetail=JSON.stringify({
                        doc_app_id:res.data[0].employee_n_key,
                        app_prod_grp_key:i.app_prod_grp_key,
                        app_payment_n_key:resp.app_payment_n_key  
                      })
                      localStorage.setItem('therapy_details',appointmentDetail)
                      this.props.history.push('therapyjourney/selectSession/',{action:"Date",previous:true})
                 }
              else{
                let py_=i
                py_.app_payment_n_key=resp.app_payment_n_key
                this.props.history.push("/creditsleft",{
                  selectP: py_,
                  creditModal: true,
                  empDetails: res.data,
                });
              }
            })
            }
            else{
              let jsonData = {
                patient_n_key: ptkey,
                app_prod_grp_key: i.app_prod_grp_key,
              };
              fetch(`${REACT_APP_BU_API_URL}/bu_initiate_payment/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(jsonData),
              })
                .then((response) => response.json())
                .then((response)=>{
                  let selectedProblem = { app_prod_n_key: "APP_PROD-3" };
                  Store.dispatch(ScheduleAction({
                    scheduleStep:0,
                    scheduleCurrentStep:0,
                    problem:selectedProblem,
                    mode:'Video',
                    data1:i,
                    app_payment_n_key:response.app_payment_n_key
                  }))
                this.props.history.push('/schedule/appointment',{data1:i,app_payment_n_key:response.app_payment_n_key})
                })
            }
          });
      } else {
        this.setState({
          selectP: i,
          nocreditModal: true,
        });
      }
    }
  }
};


render(){
  if(this.state.doctor_profile === "" || this.state.doctor_profile === null ){
    var docname = this.state.doctor_name
    var res = docname.charAt(0);
    this.setState({
      profiledocname: res
    })

  }
  const {confirmAppointment,successfulPayment, products,followUp,pending, therapyForms,creditModal,nocreditModal,events_,eventsprev_,_wellbeing,_welluser} = this.state;
  const ptkey= localStorage.getItem('_patientKey')

  return(
    <div className='container m-0 p-0 position-relative' style={{overflowY:'scroll', marginTop:20}}>
    <div className='col-md-12 mx-auto d-flex'>
    {this.state.pat_name&&
    <div className='mt-md-5 mt-5 pl-md-5 pl-1 pt-md-2 pt-5'>
        <h2 style={{fontFamily:'Source Serif Pro'}} className='font-weight-bold ml-md-5 ml-1 pl-md-5 pl-1'>Hi, {this.capitalize(this.state.pat_name)} !</h2>
    </div>}
     <ToastContainer />
    {this.state.bu_logo? <div style={{top:'-4px',right:'10px'}} className='ml-auto position-absolute mt-md-5 mt-5 ml-md-4 ml-n2 pt-md-2 pt-5 d-flex'>
      <div style={{width:'80px',height:'70px',backgroundColor:'#fff',border:'.4px solid #215352'}} className='shadow rounded  mr-3 text-center py-2'>
    <p  className=''>Session credits <br/>{this.state.overall_remaining}</p>
        </div>
        <img alt='logo' className='shadow' style={{width:'80px',height:'70px'}} src={this.state.bu_logo} />
      </div>:null}
    </div>
    {therapyForms && therapyForms.length !== 0 ? (
          <>
            <div
              class="mx-auto col-md-5 p-4 d-flex align-items-center position-relative shadow rounded mt-5 "
              style={{ background: "rgb(248, 245, 231)" }}
            >
              <span
                style={{
                  top: "2px",
                  left: "14px",
                  fontSize: "15px",
                  color: "orange",
                  fontWeight: "bold",
                }}
                className="position-absolute"
              >
                Required
              </span>
              <p className="text-left pt-3">
                Your Clinician has assigned you an assessment - { therapyForms[0]?.form_name}, tap here to
                complete
              </p>
              <button
                className="shadow rounded p-0 ml-2"
                style={{
                  background: "rgb(253, 131, 109)",
                  height: "40px",
                  fontWeight: "bold",
                  minWidth: "90px",
                  border: "none",
                  color: "rgb(255, 255, 255)",
                }}
                onClick={() =>
                  this.props.history.push("therapyAsses", {
                    order_form_id: therapyForms[0].order_form_id,
                  })
                }
              >
                Take Now
              </button>
            </div>
          </>
        ) : null}
      {!_wellbeing?<>
      <div className='col-md-5 mx-auto shadow-sm mt-md-5 p-4 bg-white rounded'>
            <h4 className='header_font_'>Unlock your inner balance with our brief Well-Being Assessment!</h4>
            <button style={{background:'#215352',color:'#fff'}} className='btn mt-4  mx-auto btn-block font-weight-bold' onClick={()=>{
            if(_welluser&&_welluser=='College'){window.location.href='/wellbeing-profile'}
              else{window.location.href='/careme-wellbeing-assessment'}
            }}>Tap here to start</button>
          </div></> :null
          }
       <Modal centered lg isOpen={creditModal} >
                {/* <ModalHeader style={{textAlign:'center'}} >Choose an option to continue with your appointment</ModalHeader> */}
                <ModalBody style={{textAlign:'center'}}>
                {this.state.empDetails[0]&&this.state.empDetails[0].is_active==0? 
                <div className="prec_check_ text_p_ font-weight-bold pt-5">
                Hey, we recently had to let our therapist {`${(this.state.empDetails[0]&&this.state.empDetails[0].first_name)+' '+(this.state.empDetails[0]&&this.state.empDetails[0].last_name)}`} go.
                 If you would like to continue with her/him, send an email <span style={{textDecorationLine:"underline"}}>{this.state.empDetails[0]&&this.state.empDetails[0].email_id}</span> or call <span style={{textDecorationLine:"underline"}}>{this.state.empDetails[0]&&this.state.empDetails[0].phone_number} </span> 
                  <br/>
                  <div>
                  <br/>
                    <p className='text_p_'>
                    You're not alone and we're here for you. We have therapists who can help you rebuild your life, learn and grow from the experience, and get back to where you were before this happened. Please reach out if you are ready to begin.
                    </p>
                  </div><br/>
                  <span style={{fontSize:12}}>Our support number: +91 7829-002-002</span>
                  <div style={{width:'70%'}} onClick={()=>this.setState({old:false,new:true})} className="prec_check mt-4 border rounded text-center mx-auto py-3">
                {this.state.new ? <BsCheck2Circle size={25} style={{marginRight:'4px'}} className="icn_span"/>:<BsCircle style={{marginRight:'4px'}} size={15} className="icn_span" />}{' '}{' '} I would like to switch therapist
                </div>
                </div>:
                <div onClick={()=>this.setState({old:true,new:false})} className="prec_check mt-4 border rounded">
                  {this.state.old ? <BsCheck2Circle size={30} className="icn_span" />:<BsCircle size={15} className="icn_span" />}  I would like to continue with my previous therapist
                  </div>}
                  {/* <span className="in_span">[OR]</span> */}
                
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({creditModal:false})}>Cancel</Button>
                <Button color="primary"
                  disabled={(this.state.old || this.state.new)?false:true}
                onClick={()=>{
                                        this.setState({creditModal:false})
                                        if(this.state.old){
                                          let appointmentDetail=JSON.stringify({
                                            doc_app_id:this.state.empDetails[0].employee_n_key,
                                            app_prod_grp_key:this.state.selectP.app_prod_grp_key
                                          })  
                                          localStorage.setItem('therapy_details',appointmentDetail)
                                          if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-1' && this.state.credits_available >= 1){
                                              this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                                            }
                                            else if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-2' && this.state.credits_available >= 1){
                                              this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                                            }
                                            else if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-3' && this.state.credits_available >= 1){
                                              this.props.history.push('therapyjourney/selectSession/',{action:"Date"})
                                            }
                                            else{
                                              let data ={
                                                journeyData:this.state.empDetails[0],
                                                prod:this.state.selectP,
                                                redirect:true,
                                              }
                                              this.props.history.push('carepay',{data:data})
                                            }
                                        }
                                    if(this.state.new){
                                      if(this.state._fol_therapist){
                                        
                                        Store.dispatch(ScheduleAction({
                                          data1:this.state.selectP,
                                          scheduleStep:0,
                                          scheduleCurrentStep:0,
                                        }))
                                        this.props.history.push('/schedule/appointment',{data1:this.state.selectP,_fol_therapist:true})
                                      }
                                      else if(this.state._paid_therapist){
                                        if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-3'){
                                          let selectedProblem={app_prod_n_key:'APP_PROD-3'};
                                          Store.dispatch(ScheduleAction({
                                            scheduleStep:0,
                                            scheduleCurrentStep:0,
                                            problem:selectedProblem,
                                            mode:'Video',
                                            data1:this.state.selectP
                                        }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       
                                       else{
                                          Store.dispatch(ScheduleAction({
                                            data1:this.state.selectP,
                                            scheduleStep:0,
                                             scheduleCurrentStep:0,
                                          }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       
                                      }
                                       else{
                                        console.log(this.state.selectP,this.state.credits_available,this.state.credits_available >= 1)
                                        if(this.state.selectP.app_prod_grp_key == 'APP-PROD-CAT-3' && this.state.credits_available >= 1){
                                          let selectedProblem={app_prod_n_key:'APP_PROD-3'};
                                          Store.dispatch(ScheduleAction({
                                            scheduleStep:0,
                                            scheduleCurrentStep:0,
                                            problem:selectedProblem,
                                            mode:'Video',
                                            data1:this.state.selectP
                                        }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       
                                       else if(this.state.credits_available >= 1){
                                          Store.dispatch(ScheduleAction({
                                            data1:this.state.selectP,
                                            scheduleStep:0,
                                            scheduleCurrentStep:0,
                                          }))
                                          this.props.history.push('/schedule/appointment',{data1:this.state.selectP,})}
                                       else{
                                          let data =
                                          {
                                            back:'bproductView',
                                            previous:'No',
                                            type:'offerings',
                                            prod:this.state.selectP
                                          }
                                          this.props.history.push('carepay',{data:data})
                                        }
                                    }
                                  }
                }}>Continue</Button>{' '}
                </ModalFooter>
            </Modal>
            <Modal centered isOpen={nocreditModal} >
                <ModalHeader style={{textAlign:'center'}} ></ModalHeader>
                <ModalBody>
                <h3> We’re here to help.
                  </h3>
               <p className="mod_p">
              Sorry! You do not have sufficient credit on your account to book this appointment. However, you can pay the amount and continue booking your appointment with our providers
                </p> 
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={()=>this.setState({nocreditModal:false})}>Cancel</Button>
                <Button color="primary" onClick={()=>{
                let jsonData={
                        patient_n_key:localStorage.getItem("_patientKey"),
                        app_prod_grp_key:this.state.selectP&&this.state.selectP.app_prod_grp_key
                      }
                      fetch(`${REACT_APP_API_URL}/previous_check/`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                        },
                        body: JSON.stringify(jsonData),
                      })
                        .then((response) => response.json()).then((res)=>{
                        console.log(res)
                        if(res.status=='success'){
                          if(res.data[0].is_active==1){
                            let data={
                                journeyData:res.data[0],
                                prod:this.state.selectP,
                                redirect:true,
                              }
                            this.props.history.push('carepay',{back:'bproductView', type:'offerings',data:data})
                          }
                          else{
                            this.setState({
                              creditModal:true,
                              empDetails:res.data,
                              nocreditModal:false
                            })
                          }
                        }
                        else{
                         let data={ 
                          type:'offerings',
                          prod:this.state.selectP}
                          this.props.history.push('carepay',{back:'bproductView', type:'offerings',data:data})
                          this.setState({nocreditModal:false})}
                      })
                    
                    
                }}>Start Consult</Button>{' '}
                </ModalFooter>
            </Modal>

    

{confirmAppointment.length !== 0 && confirmAppointment.map((item, index)=>{
  return(
    this.showAppointment(item) && 
    <div className='col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'65px',height:'65px'}} src={`${CDN_URL}/APP/appointment.png`} />
      <h3 className='pl-1 pt-4' style={{fontFamily:'Source Serif Pro',fontSize:'18px'}}>Appointment Confirmed!</h3>
    </div>
    <div className="row px-3 mt-2">
      <p className='text_p_ text_medium_'>
      We're looking forward to speaking with you, {item.patient_name}! Your upcoming session has been scheduled at {this.dateConversion(item && item.appointment_date)} with {item.doctor_name}</p>
    </div>
    <div className="row px-3 text-center mt-2 d-flex flex-row justify-content-center">
    {item.mode_of_telehealth ==="Video" && <button className={`btn  m-1 ${!this.showbutton(item)?'btn-block':''} ${!this.showbutton(item)?'btn_Schdule':'btn_Schdules'}`} onClick={()=>this.getMeetingid(item,item && item.appointment_n_key)}>Join Call</button>}
    {item.mode_of_telehealth ==="Audio" && <button className={`btn m-1 ${!this.showbutton(item)?'btn-block':''} ${!this.showbutton(item)?'btn_Schdule':'btn_Schdules'}`} onClick={()=>this.getMeetingid(item, item && item.appointment_n_key)}>Call Now</button>}
    {item.mode_of_telehealth ==="Chat" && <button className={`btn  m-1 ${!this.showbutton(item)?'btn-block':''} ${!this.showbutton(item)?'btn_Schdule':'btn_Schdules'}`} onClick={()=>this.getMeetingid(item,item && item.appointment_n_key)}>Join Chat</button>}
    {this.showbutton(item) && <button className="btn btn_Schdules m-1" style={{fontSize:'10px'}} onClick={()=>this.rescheduleAppointment(item && item)}>Reschedule</button>}
    {this.showbutton(item) &&  <button className="btn btn_Schdules m-1" style={{fontSize:'10px'}} onClick={()=>this.cancelReason(item)}>Cancel</button>}
    </div>
    </div>
    )
  })
  }
  
 { confirmAppointment.length==0 && successfulPayment.length==0 &&pending.length==0 && followUp.length !== 0 && followUp.map((item, index)=>{
  return(
    <div className='col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'55px',height:'55px'}} src={`${CDN_URL}/APP/app/measring.png`} />
      <h3 className='pt-3 pl-2' style={{fontFamily:'Source Serif Pro'}}>Book Your Folloup</h3>
    </div>
    <div className="row px-3 mt-2">
    <p className='text_p_ text_medium_'>
    We’re ready when you are. Let's start this Journey! Your therapist <a style={{textDecoration:'underline',color:'blue',cursor:"pointer",zIndex:99}} href={`https://careme.health/provider/${item.doc_app_id}`} target="_blank">{item.doctor_name}</a>{' '}
    {this.state.credits_available >0? `has recommended ${item.total_sessions_recommended} sessions for you.`: `has crafted a personalised care plan for you`} </p>
   </div>
    <div className="row px-3 text-center mt-2">
      <button className='btn btn-block btn_Schdule' onClick={()=>this.scheduleApp(item && item)}> {this.state.credits_available>0? 'Book Now':'View care plan'}</button>
    </div>
    </div>

    )
  })
  }
   { confirmAppointment.length==0 && successfulPayment.length==0 && pending.length !== 0 && pending.map((item, index)=>{
  return(
    <div className='col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'35px',height:'35px'}} src={`${CDN_URL}/APP/calendar1.png`} />
      <h3 className='pt-2 pl-2' style={{fontFamily:'Source Serif Pro'}}><b>{item.remaining_session}</b> Upcoming Sessions</h3>
    </div>
    <div className="row px-3 mt-2">
      <p className='text_p_ text_medium_'>
    That's Awesome! you have completed {item.total_session - item.remaining_session} sessions out of {" "}
                {item.total_session}, {" "}
                 let's progress towards your goals, re-evaluating along the
                 way-by your therapist!</p>
    </div>
    <div className="row px-3 text-center mt-2">
      <button className='btn btn-block btn_Schdule' onClick={()=>this.SchedulePendng(item && item)}>Schedule Now</button>
    </div>
    </div>
    )
  })
  }
     {confirmAppointment.length ==0 && successfulPayment.length !== 0 && successfulPayment.map((item, index)=>{
  return(
    
    <div className='col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3' >
    <div className="row px-3 text-center">
      <img style={{width:'65px',height:'65px'}} src={`${CDN_URL}/APP/appointment.png`} />
      <h3 className='pt-3 pl-2' style={{fontFamily:'Source Serif Pro'}}><b>{item.remaining_session}</b> Book your session</h3>
    </div>
    <div className="row px-3 mt-2">
      <p className='text_p_ text_medium_'>
      We've received your payment and our therapist is waiting for you, click book now to book your appointment</p>
    </div>
    <div className="row px-3 text-center mt-2">
      <button className=' btn btn-block btn_Schdule' onClick={()=>this.scheduleAppointment(item && item)}>Book Now</button>
    </div>
    </div>
    )
  })
  }

<div style={{borderRadius:'12px'}} className='col-md-10 ml-auto mr-md-5 draw shadow-sm p-4 bg-white mt-5 mb-3' >
           <div className="d-flex flex-row ">
              <div className='ml-md-3 ml-1 pt-3'>
                <h5 style={{fontSize:'15px'}} className='_text_color text-left text_p_ font-weight-bold'>RECOMMENDED</h5>
                <h1 className='_text_color text-left header_font_'>Start 1-to-1 sessions</h1>
                <p className='_text_color text-left text_p_ col-md-8 ml-0 p-0'>Get matched with coach or therapist for recurring 1-to-1 sessions to work on your focus topic, <span style={{fontWeight:'bold'}}>mindfulness and meditation</span>.</p>
                <button onClick={()=>{window.location.href="/offerings"}} className='btn_Schdules'>Get matched</button>
              </div>
              <div className='d-none d-sm-block'>
                 <img style={{width:'435px',height:'225px'}} src={`${CDN_URL}/Websiteicons/iccons.png`} />
              </div>
           </div>
    </div>
     
    <div style={{borderRadius:'6px'}} className='col-md-10 ml-auto mr-md-5 p-1 mt-5 mb-3'>
      <h3 style={{fontSize:'25px'}} className='header_font_ _text_color'><BsCalendar2CheckFill size={25} />{events_[0]? 'Upcoming Events' :'Events'}</h3>
        <div  className='d-flex flex-md-row flex-column  mt-3'>
                {events_&&events_.length>0?
               <div className='d-flex flex-md-row flex-column bg-white col-md-8 draw shadow-sm rounded-lg'>
               <div className='flex-fill col-md-5 align-self-center d-lg-none d-block'>
                <img className='rounded img-fluid' src={events_[0].image_url||CAreMEHEALTH} />
               </div>
               <div className=' d-none d-sm-block flex-fill col-md-5 align-self-center'>
               <img className='rounded img-fluid align-self-center' src={events_[0].image_url||CAreMEHEALTH} style={{}} />
               </div>
                <div className='col-md-7 flex-fill'>
                  <div className='d-flex flex-fill flex-row py-1'>
                  <p style={{fontSize:'12px'}} className='text_p_ _text_color border rounded p-2 mt-3 ml-3'><Calendar size={14} /> {moment(events_[0].event_start, 'YYYY-MM-DD hA').format('Do MMMM YYYY, hA')}</p>
                  <p style={{fontSize:'12px'}} className='text_p_ _text_color border rounded p-2 mt-3 ml-3'><PlayCircle size={14} /> Video session </p> 
                  </div>
                  <h3 className='header_font_ _text_color ml-3 mt-n3 pt-2'>{events_[0].event_name}</h3>
                  <p className='text_p_ ml-3 _text_color'>{events_[0].comments.length > 30 ? events_[0].comments.slice(0, 132) + '...' : events_[0].comments}</p>
                  <hr className='ml-3' style={{color:'#dcdcdc'}} />
                  <h5 style={{opacity:.6}} className='d-flex ml-3 header_font_ _text_color'><BsPersonBadge /> {events_[0].host}</h5>
                  <button className='btn_Schdules ml-3 mb-3 mt-2 rounded' onClick={()=>{window.open(events_[0].url)}}>Join Event</button>
                </div>
              </div>:
                    <div className='shadow-sm bg-white rounded p-2'>
                    <div className='d-flex flex-row py-3 align-content-center'>
                        <div>
                         <img className='img-fluid' style={{maxWidth:'180px'}} src={`${CDN_URL}/CaremeLogo/finallogo1copy.png`} />
                        </div>
                        <div className='align-self-center '>
                          <h4 className='header_font_ _text_color text-left'>
                          We're working hard to bring you exciting events and webinars.
                          In the meantime, feel free to explore our past events and
                          resources.
                          </h4>
                          <div className='d-flex flex-wrap'>
                                  <a href="https://careme.health/blog/" target='_blank' className="blue-link _text_color text_p_ m-2"><FaBook size={20} /> Dive into Our Blog</a>
                                  <a href="https://careme.health/webinars" target='_blank' className="blue-link _text_color text_p_ m-2"><FaVideo size={20} /> Explore Our Webinar Series</a>
                                  <a href="https://careme.health/press" target='_blank' className="blue-link _text_color text_p_ m-2"><BsNewspaper size={20} /> Read Our Press Releases</a>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                              <p style={{opacity:.7}} className='text_p_ _text_color'>Stay Updated with Our Social Media:</p>
                              <div className="social-icons">
                              <a className='m-1' target='_blank' href="https://www.facebook.com/caremehealth">
                                    <FaFacebookSquare size={18} style={{ color: '#1877F2' }} />
                                  </a>
                                  <a className='m-1' target='_blank' href="https://twitter.com/careme_health">
                                    <FaTwitterSquare size={18} style={{ color: '#1DA1F2' }} />
                                  </a>
                                  <a className='m-1' target='_blank' href="https://www.instagram.com/careme.health/">
                                    <FaInstagramSquare size={18} style={{ color: '#C13584' }} />
                                  </a>
                                  <a className='m-1' target='_blank' href="https://www.youtube.com/channel/UCeFSGKESE0eFlHW5xyiaryQ?themeRefresh=1">
                                    <FaYoutubeSquare size={18} style={{ color: '#FF0000' }} />
                                  </a>
                                  <a className='m-1' target='_blank' href="https://in.linkedin.com/company/caremehealth">
                                    <FaLinkedin size={18} style={{ color: '#0A66C2' }} />
                                  </a>
                                  <a className='m-1' target='_blank' href="https://in.pinterest.com/caremehealth/">
                                    <FaPinterestSquare size={18} style={{ color: '#E60023' }} />
                                  </a>
                                  <a className='m-1' target='_blank' href="https://caremehealth.medium.com/">
                                    <FaMedium size={18} style={{ color: '#12100E' }} />
                                  </a>
                              </div>
                            </div>
                  </div>
                  }
            
            <div style={{borderRadius:'6px'}} className='draw shadow-sm bg-white ml-md-3 col-md-4 mt-2 mt-md-0 p-2 px-3'>
              {eventsprev_&&eventsprev_.length>0&&<div className=''>
                  <h5 style={{opacity:.5,fontSize:'14px'}} className='text_p_ pl-2 pt-2 font-weight-bold _text_color'>POPULAR EVENTS</h5>
                  <div onClick={()=>{window.open(`https://careme.health/webinar/${eventsprev_[0].webinar_id}`)}} className='d-flex flex-row mt-3'>
                    <img style={{width:'50px',height:'50px',objectFit:'fill'}} className='rounded-circle' src={eventsprev_[0].banner_image} />
                    <div>
                      <h5 className='header_font_ ml-3 mt-3 _text_color'>{eventsprev_[0].title}</h5>
                      <p style={{opacity:.6}}  className='text_p_ ml-3 _text_color mt-n1'>{moment(eventsprev_[0].webinar_at, "DD/MM/YYYY hh:mm A").format("Do MMMM YYYY, h.mm A")}</p>
                    </div>
                  </div>
                  <hr className="dotted-line my-0" />
                  <div onClick={()=>{window.open(`https://careme.health/webinar/${eventsprev_[1].webinar_id}`)}} className='d-flex flex-row '>
                    <img style={{width:'50px',height:'50px',objectFit:'fill'}} className='rounded-circle' src={eventsprev_[1].banner_image} />
                    <div>
                      <h5 className='header_font_ ml-3 mt-3 _text_color'>{eventsprev_[1].title}</h5>
                      <p style={{opacity:.6}}  className='text_p_ ml-3 _text_color mt-n1'>{moment(eventsprev_[1].webinar_at, "DD/MM/YYYY hh:mm A").format("Do MMMM YYYY, h.mm A")}</p>
                    </div>
                  </div>
                  <div style={{maxWidth:'90%'}} className='ml-2 my-3'>
                    <button onClick={()=>{window.open('https://careme.health/webinars')}} style={{background:'#ddefe5',color:'#215352',border:'none'}} className='btn-block py-2 rounded font-weight-bold'>View All Events <ArrowRight /> </button>
                  </div>
              </div>}
            </div>
        </div>
    </div>

{products && 
   <div className='col-md-10 mx-auto mt-5 p-1'>
     <h3 className='font-weight-bold ml-md-5 pl-md-3 _text_color'>Your Care</h3>
       <div className='d-flex flex-md-row flex-column ml-md-5 justify-content-between px-5'>
        <div 
         className='shadow-sm d-flex flex-column col-md-3 p-2 ml-md-5 ml-n2 mb-2 mt-md-3 mt-4 bg-white rounded position-relative'
        onClick={()=>{this.goToPayment(products&&products[0],products&&products[0]?.app_prod_grp_key)}}
         >
        <img className='img-fluid text-center mt-4' style={{maxHeight:'55px'}} src={`${CDN_URL}/Websiteicons/NewHomepage/4set/Individ.png`} />
        <h3 className='text-center header_font_ _text_color mt-3' >Individual</h3>
        <p className='text-center text_p_ _text_color'>For Myself</p>
        <button style={{background:'#ddefe5',color:'#215352',border:'none'}} className='py-2 font-weight-bold'>Book Now</button>
        </div>
        <div 
        onClick={()=>{this.goToPayment(products&&products[1],products&&products[1]?.app_prod_grp_key)}} className='shadow-sm d-flex flex-column col-md-3 p-2 ml-md-3 ml-n2 mb-2 mt-md-3 mt-2 bg-white rounded position-relative'>
        <img src={`${CDN_URL}/Websiteicons/NewHomepage/4set/Couplee.png`} className='img-fluid text-center mt-4' style={{maxHeight:'55px'}}/>
        <h3 className='text-center header_font_ _text_color mt-3'>Couple</h3>
        <p className='text-center text_p_ _text_color'>For Me and my partner</p>
        <button style={{background:'#ddefe5',color:'#215352',border:'none'}} className='py-2 font-weight-bold'>Book Now</button>
        </div>
        <div onClick={()=>{this.goToPayment(products&&products[2],products&&products[2]?.app_prod_grp_key)}} className='shadow-sm d-flex flex-column col-md-3 p-2 ml-md-3 ml-n2 mb-2 mt-md-3 mt-2 bg-white rounded position-relative'>
        <img src={`${CDN_URL}/Websiteicons/NewHomepage/4set/Psychitry.png`} className='img-fluid text-center mt-4' style={{maxHeight:'55px'}}/>
        <h3 className='text-center header_font_ _text_color mt-3'>Psychiatry</h3>
        <p className='text-center text_p_ _text_color'>Medication</p>
        <button style={{background:'#ddefe5',color:'#215352',border:'none'}} className='py-2 font-weight-bold'>Book Now</button>        
        </div>
        <div onClick={()=>this.joinFreeChat()} className='shadow-sm ml-md-3 ml-n2 d-flex  flex-column col-md-3 p-2 mb-2 mt-md-3 mt-2 bg-white rounded position-relative'>
        <img src={`${CDN_URL}/Websiteicons/NewHomepage/4set/coach.png`} className='img-fluid text-center mt-4' style={{maxHeight:'55px'}}/>
        <h3 className='text-center header_font_ _text_color mt-3'>Coach Chat</h3>
        <p className='text-center text_p_ _text_color'>24 x 7 Support</p>
        <button style={{background:'#ddefe5',color:'#215352',border:'none'}} className='py-2 font-weight-bold'>Book Now</button>      
          </div>
   </div>
   </div>
   }



   
</div>
  )
}
};

export default withRouter(SelectedService);



