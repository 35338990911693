import React from "react";
import { Circle } from "react-feather";
import { BsCheck2Circle, BsCheckCircle, BsCircleFill } from "react-icons/bs";
import { CDN_URL } from "../../processENV";

const StepItem = ({ step, min, max, label, isLast }) => {
  const isActive = step >= min && step < max;
  const isCompleted = step >= max;

  return (
    <>
      <p className={`text-white text_p_ h5  font-weight-normal ${isActive ? "" : "opacity-50"}`}>
        <span className="mr-2">
          {isActive ? (
            <BsCircleFill size={14} />
          ) : isCompleted ? (
            <BsCheckCircle  size={14} />
          ) : (
            <Circle size={16} />
          )}
        </span>
        {label}
      </p>
      {!isLast && (
        <div
          className={` ${isActive ? "bg-white" : "bg-light"} rounded`}
          style={{ width: "3px", height: "20px", marginLeft: "5px" }}
        ></div>
      )}
    </>
  );
};

const LeftContent = ({ step }) => {
  const steps = [
    { min: 0, max: 10, label: "Energy Check: Burnout & Work-Life Flow" },
    { min: 11, max: 13, label: "Stress Reality Check" },
    { min: 14, max: 16, label: "EQ Vibes: Unlock Your Emotional Superpowers" },
    { min: 17, max: 19, label: "Work Vibes Check" },
    { min: 20, max: 22, label: "Resilience Reality Check" },
    { min: 23, max: 25, label: "Life-Work Harmony Check" },
    { min: 26, max: 28, label: "Team Vibe Check" },
    { min: 29, max: 31, label: "Role Reality Check" },
    { min: 32, max: 34, label: "Workplace Support Check" },
    { min: 35, max: 37, label: "Personality Vibe Check" },
    { min: 38, max: 40, label: "Team Safe Space Check" },
    { min: 41, max: 43, label: "Digital Wellness Check" },
  ];

  return (
    <div
    className="col-md-4 rounded mx-5 mt-3 d-none d-lg-block text-center vh-100 overflow-y-scroll"
    style={{backgroundColor: '#215352',overflowY:'auto',paddingBottom:'125px' }}
    >
      {/* <div className="d-flex justify-content-center align-items-center"> */}
        
      <div className="mt-md-2 pt-md-2">
      <img
                    src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20landscape%20copy.png`}
                    className="img-fluid"
                    style={{ maxHeight: "70px" }}
                  />
        {/* <h2 className="text-white font-weight-bold">CareMe Health</h2>
        <p className="text-white font-weight-bold mt-md-n2">Feel better. Live better.</p> */}
      {/* </div> */}
      </div>
      
      <h4 className="mt-md-3 text-white text-center text_p_ h2 font-weight-bold">Understand Your Wellbeing.</h4>
      <div className="mt-md-4 pl-5 text-left pb-3 pb-md-4">
        {steps.map(({ min, max, label }, index) => (
          <StepItem
            key={index}
            step={step}
            min={min}
            max={max}
            label={label}
            isLast={index === steps.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default LeftContent;
