import React, { useEffect, useState } from "react";
import { CDN_URL } from "../../processENV";
import { Flash } from "react-ionicons";
import { PhoneCall, Unlock } from "react-feather";
import { BsHouse, BsWhatsapp } from "react-icons/bs";
import { postReq, useOverallData } from "../../../utils/apicall";
import moment from "moment";
import { useHistory } from "react-router-dom";

const ResultComponent = () => {
  const [data, setData]=useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const resp = await postReq(`/business-wellbeing/get-results/`, {
      patient_n_key: localStorage?._patientKey,
    });
    console.log(resp);
    if(resp.data.status==='success'){
      setData(resp.data.data);
    }
  };

  const history = useHistory();
  const firstelement = () => {
    if (data) {
      const keys = Object.keys(data);
      const firstKey = keys[0];
      const createdOnValue = data[firstKey];
      return createdOnValue;
    }
  };
  const isCreatedWithin14Days = () => {
    const today = moment().startOf("day");
    const createdOnDate = moment(firstelement()?.created_on);
    const dateDifferenceInDays = today.diff(createdOnDate, "days");
    const daysLeft = 14 - dateDifferenceInDays;
    const isWithin14Days =
      dateDifferenceInDays >= 0 && dateDifferenceInDays <= 14;
    const dateLeft = isWithin14Days
      ? today.clone().add(daysLeft, "days")
      : null;
    return {
      isWithin14Days: isWithin14Days,
      daysLeft: isWithin14Days ? daysLeft : null,
      dateLeft: moment(dateLeft).format("DD-MM-YYYY"),
    };
  };
  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).replaceAll('_',' ');
  };
  const handleuser = () => {
    if (localStorage._patientKey) {
      window.location.href = "/home";
    } else window.location.href = "https://app.careme.health/";
  };
  return (
    <div className="d-flex flex-column flex-md-row col-md-10 mx-md-5 mx-0 p-0 p-md-3">
      <div className="col-md-9 mx-auto p-0 table-responsive">
      <button
                  onClick={() => window.location.href="/home"}
                  className="btn ml-md-3 ml-2 bg-white"
                  style={{
                    borderColor: "#215352",
                    borderWidth: "1px",
                    color: "#215352",
                  }}
                >
                  <BsHouse /> Go to dashboard
                  </button>
        <div className="col-md-9 mx-auto mt-2 mt-md-3 d-flex flex-column flex-md-row p-0">
          <img
            src={`${CDN_URL}/CaremeLogo/final%20logo%20with%20slogan%20copy.png`}
            className="img-fluid"
            style={{ maxHeight: "100px" }}
          />
          <h5
            style={{ opacity: 0.9 }}
            className="text_p_ font-weight-bold text-left ml-3 d-block d-md-none _text_color align-self-center"
          >
            Your Personalized Wellbeing Roadmap 🗺️ - Embracing Balance and
            Growth 🌱
          </h5>
          <h5
            style={{ opacity: 0.9 }}
            className="text_p_ font-weight-bold text-left h2 ml-3 d-md-block d-none _text_color align-self-center"
          >
            Your Personalized Wellbeing Roadmap 🗺️ - Embracing Balance and
            Growth 🌱
          </h5>
        </div>
        <div className="d-lg-none d-sm-block my-2 px-2">
          <div className="text-center">
            <button
             style={{
              color: "#000",
              border: "1px solid rgb(40, 39, 39)",
              fontWeight: "bold",
            }}
            className="btn my-3 py-2 btn-block"
            >
              Download our mobile app now!
            </button>
          </div>
          <div className="text-center mt-1">
          <div className="d-flex flex-row justify-content-between">
              {/* Banner 1 */}
              <div
                className="mr-2 rounded d-flex flex-column align-items-center justify-content-center p-1 mt-2"
                style={{
                  maxHeight: "120px",
                  maxWidth: "110px",
                  background: "#ffe7c4",
                }}
              >
                <Flash size={22} color={"black"} />
                <p
                  className="text-dark text-center m-0 p-0"
                  style={{ fontSize: "10px" }}
                >
                  Take a step towards better mental well-being.
                </p>
              </div>

              {/* QR Code */}
              <div>
                <img
                  src={`${CDN_URL}/Others/qr-code.png`}
                  className="img-fluid text-center m-1"
                  style={{ minHeight: "120px" }}
                />
              </div>

              {/* Banner 2 */}
              <div
                style={{
                  maxHeight: "120px",
                  maxWidth: "110px",
                  background: "#c4f0ff",
                }}
                className="ml-3 mt-2 p-2 rounded"
              >
                <p className="m-0 pb-2" style={{ fontSize: "8px" }}>
                  Always Here for You
                </p>
                <div className="d-flex justify-content-around">
                  <PhoneCall size={14} />
                  <BsWhatsapp size={14} />
                </div>
                <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                <p className="m-0 text-left pt-1" style={{ fontSize: "7px" }}>
                  Available 24/7 for your support and care. Whenever you're
                  ready, we're here.
                </p>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-bordered pb-2 mt-md-4 pb-md-4 table-fixed rounded-lg table-responsive-md border">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <thead className="rounded">
            <tr style={{ backgroundColor: "#ebf0ed" }} className="rounded">
              <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                Category
              </th>
              <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                Needs Attention
              </th>
              <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                Improving
              </th>
              <th className="py-4 _text_color text-center text_p_ font-weight-bold h5">
                Well-balanced
              </th>
              <th className="py-4 _text_color  text-center text_p_ font-weight-bold h5"></th>
            </tr>
          </thead>
          <tbody>
            {data && Object.keys(data).length > 0 &&
              Object.keys(data).map((category, index) => (
                <tr
                  onClick={() =>
                    history.push("/assesment-results", {
                      data: data[category],
                    })
                  }
                  className="py-2"
                  key={index}
                >
                  <td className="text_p_ h6 align-middle _text_color text-break">
                    <p className="p-0 m-0">{capitalize(category)}</p>
                  </td>
                  <td className="align-middle">
                    {data[category].progress_status === "Low" && (
                      <div className="position-relative">
                        <div
                          style={{ backgroundColor: "#f5a97a" }}
                          className="banner-text rounded"
                        >
                          {data[category].assessment_score}
                        </div>
                        <div
                          className="text-center mx-auto pt-4"
                          style={{
                            backgroundColor: "#f5a97a",
                            height: "34px",
                            width: "3px",
                          }}
                        ></div>
                        <div
                          style={{ height: "10px" }}
                          className="progress mt-0"
                        >
                          <div
                            className="progress-bar "
                            role="progressbar"
                            style={{
                              width: "100%",
                              height: "10px",
                              backgroundColor: "#f5a97a",
                            }}
                            aria-valuenow="33.33"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="align-middle">
                    {data[category].progress_status === "Moderate" && (
                      <div className="position-relative">
                        <div
                          style={{ backgroundColor: "#73a671" }}
                          className="banner-text rounded"
                        >
                          {data[category].assessment_score}
                        </div>
                        <div
                          className="text-center mx-auto pt-4"
                          style={{
                            backgroundColor: "#73a671",
                            height: "34px",
                            width: "3px",
                          }}
                        ></div>
                        <div style={{ height: "10px" }} className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: "100%",
                              height: "10px",
                              backgroundColor: "#73a671",
                            }}
                            aria-valuenow="66.66"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="align-middle">
                    {data[category].progress_status === "Excellent" && (
                      <div
                        // style={{ width: "80%" }}
                        className="mt-0 d-flex flex-row"
                      >
                        <div className="position-relative flex-grow-1">
                          <div
                            style={{ backgroundColor: "#698ec9" }}
                            className="banner-text rounded"
                          >
                            {data[category].assessment_score}
                          </div>
                          <div
                            className="text-center mx-auto pt-4"
                            style={{
                              backgroundColor: "#698ec9",
                              height: "34px",
                              width: "3px",
                            }}
                          ></div>
                          <div style={{ height: "10px" }} className="progress ">
                            <div
                              className="progress-bar "
                              role="progressbar"
                              style={{
                                width: "100%",
                                backgroundColor: "#698ec9",
                                height: "10px",
                              }}
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="align-middle">
                    <button
                      style={{ background: "#edf2f0", color: "#215352" }}
                      className="btn d-flex  mx-auto align-items-center"
                    >
                      <Unlock size={18} className="mr-1" /> Unlock Insights
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div
          className="md-mx-auto mx-2 mt-md-5 mt-2 rounded"
          style={{ backgroundColor: "#f5f5f3" }}
        >
          <div className="rounded-box p-3 d-flex rounded">
            <div
              style={{ opacity: 0.7, fontSize: "16px" }}
              className="text_p_  font-weight-bold"
            >
              <b style={{ textDecoration: "underline" }}>
                📅 Next Assessment Date: {isCreatedWithin14Days().dateLeft}
              </b>{" "}
              This periodic check-in helps us provide you with the best support
              and guidance tailored to your evolving needs. Remember, taking
              regular assessments allows for better understanding and
              intervention. Your journey towards better mental health is a
              continuous process, and we're here to support you every step of
              the way.
            </div>
          </div>
        </div>
        <h3 className="mt-3 pl-2 mt-md-5 header_font_ _text_color ">Trends</h3>
        <div
          className="md-mx-auto mx-2 mt-md-3  mt-2 mb-md-5 rounded"
          style={{ backgroundColor: "#f7f4f2" }}
        >
          <div className="rounded-box rounded p-3">
            <p className="text-center mx-auto py-1 h1 ">📈</p>
            <div
              style={{ opacity: 0.8, fontSize: "16px" }}
              className="text_p_  font-weight-bold"
            >
              If your record has less than two entries, we encourage you to
              record your well-being at least twice to unlock the full potential
              of the chart. By recording your well-being on multiple occasions,
              you can see how different factors can affect your journey and spot
              valuable patterns and trends.
            </div>
          </div>
        </div>
        <div className="text-center mt-3">
          <button
            onClick={handleuser}
            className="btn"
            style={{
              background: "#215352",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Go to dashboard
          </button>
        </div>
      </div>
      <div
        className="col-md-4 ml-md-5 ml-2 pt-3 rounded shadow-sm mt-md-5 mt-4"
        style={{
          backgroundColor: "#f7f4f0",
          height: "780px",
          overflow: "hidden",
          top: "30px",
          position: "sticky",
        }}
      >
        {/* Header Section */}
        <div
          style={{ borderWidth: "7px", color: "#2c2c2c" }}
          className="font-weight-bold h2 py-2 border-white text-center border-bottom"
        >
          Download Our App!
        </div>
        <p className="text_p_ h5 text-center py-2" style={{ color: "#5c5c5c" }}>
          Get the best experience by downloading our app. Access exclusive
          features and content.
        </p>

        {/* List Items */}
        <div>
          {[
            {
              icon: "🌿",
              title: "Elevate your self-care:",
              text: "Find peace with mindfulness, meditation, and relaxation in our app",
            },
            {
              icon: "🌟",
              title: "Connect and thrive:",
              text: "Join a caring community, share, and gain strength together.",
            },
            {
              icon: "🧘‍♂️",
              title: "Personalized guidance:",
              text: "Navigate life confidently with expert online counseling.",
            },
            {
              icon: "🌈",
              title: "Expert insights, anytime:",
              text: "Tailored strategies for your well-being from top psychiatrists.",
            },
            {
              icon: "🌌",
              title: "24/7 support:",
              text: "Get inspired and guided by coaches, anytime.",
            },
            {
              icon: "🌱",
              title: "Shape your well-being journey:",
              text: "Your feedback shapes a brighter future for all.",
            },
          ].map((item, index) => (
            <div key={index} className="pb-3 ">
              <span
                className="text_p_"
                style={{ fontSize: "15px", color: "#5c5c5c" }}
              >
                <strong className="text_p_" style={{ color: "#2c2c2c" }}>
                  {item.icon} {item.title}
                </strong>{" "}
                {item.text}
              </span>
            </div>
          ))}
        </div>

        {/* Button Section */}
        <div className="d-sm-none d-lg-block d-none">
          <div className="text-center">
            <button
              style={{
                color: "#000",
                border: "1px solid rgb(40, 39, 39)",
                fontWeight: "bold",
              }}
              className="btn my-3 py-2 btn-block"
            >
              Download now
            </button>
          </div>

          {/* Highlight Banners */}
          <div className="text-center mt-1">
            <div className="d-flex flex-row justify-content-between">
              {/* Banner 1 */}
              <div
                className="mr-2 rounded d-flex flex-column align-items-center justify-content-center p-1 mt-2"
                style={{
                  maxHeight: "120px",
                  maxWidth: "110px",
                  background: "#ffe7c4",
                }}
              >
                <Flash size={22} color={"black"} />
                <p
                  className="text-dark text-center m-0 p-0"
                  style={{ fontSize: "10px" }}
                >
                  Take a step towards better mental well-being.
                </p>
              </div>

              {/* QR Code */}
              <div>
                <img
                  src={`${CDN_URL}/Others/qr-code.png`}
                  className="img-fluid text-center m-1"
                  style={{ minHeight: "120px" }}
                />
              </div>

              {/* Banner 2 */}
              <div
                style={{
                  maxHeight: "120px",
                  maxWidth: "110px",
                  background: "#c4f0ff",
                }}
                className="ml-3 mt-2 p-2 rounded"
              >
                <p className="m-0 pb-2" style={{ fontSize: "8px" }}>
                  Always Here for You
                </p>
                <div className="d-flex justify-content-around">
                  <PhoneCall size={14} />
                  <BsWhatsapp size={14} />
                </div>
                <b style={{ fontSize: "10px" }}>+91 7829-002-002</b>
                <p className="m-0 text-left pt-1" style={{ fontSize: "7px" }}>
                  Available 24/7 for your support and care. Whenever you're
                  ready, we're here.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* App Store & Play Store Buttons */}
        <div className="d-flex justify-content-between mt-2 py-2 px-0">
          <img
            className=""
            onClick={() =>
              window.open("https://careme.onelink.me/01QR/bpmu5cly")
            }
            style={{ width: "170px", height: "46px", cursor: "pointer" }}
            src={`${CDN_URL}/Websiteicons/playstore.png`}
          />
          <img
            className=""
            onClick={() =>
              window.open("https://careme.onelink.me/01QR/bpmu5cly")
            }
            style={{ width: "170px", height: "46px", cursor: "pointer" }}
            src={`${CDN_URL}/Websiteicons/appstore.png`}
          />
        </div>

        {/* Footer Section */}
        <div className="h6 py-2 text-center text_p_">
          For more details please visit{" "}
          <a
            href="https://careme.health"
            target="_blank"
            style={{ color: "#4e8cff" }}
          >
            www.careme.health
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResultComponent;
